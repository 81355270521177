// react/gatsby imports
import React, { useEffect } from "react";

const GraphicMetaphorsInIdentityPage = () => {
  // 17.06.24 Настроили переадресацию на новую страницу
  useEffect(() => {
    window.open("https://artpavlushin.ru/campus/graphic-metaphors", "_self");
  }, []);

  return <></>;
};

export default GraphicMetaphorsInIdentityPage;
